import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgWorkCover from '../assets/image/cover/youknow_projectPrieview.webp'

import imgS2 from '../assets/image/details/you-know-me-well/lips.jpeg'
import imgS3 from '../assets/image/details/you-know-me-well/snow-squat.jpeg'
import imgS4 from '../assets/image/details/you-know-me-well/etno.jpeg'
import imgS5 from '../assets/image/details/you-know-me-well/details-3.jpg'
import imgS6 from '../assets/image/details/you-know-me-well/toy.jpeg'
import imgS8 from '../assets/image/details/you-know-me-well/wu-tang.jpeg'
import imgS7 from '../assets/image/details/you-know-me-well/details-7.jpg'
// import { device } from "../utils";
import FashionSubheader from '../components/FashionSubheader'

// const handleLiveWorkClicked = (event) => {
//   event.stopPropagation()
//   window.open('https://youknowmewell.net/', '_blank');

// }

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">Styling</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {' '}
                  YOU KNOW ME WELL aka Kami, is a German-born, Berlin-based
                  artist and designer. His work sits between fine art, pop-, and
                  bootlegging-culture. I used his artworks as a canvas for a
                  photoshot.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          {/* <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-5
              ">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  Berlinomania
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-5
              ">
                <Text variant="tag">Time Scope</Text>
                <Title variant="cardBig" className="mt-3">
                  2 Months
                </Title>
              </Col>
              <Col lg="4">
                <Button onClick={handleLiveWorkClicked} arrowRight>Live work</Button>
              </Col>
            </Row>
          </Container> */}
          <FashionSubheader
            client={'You know me well'}
            credits={[
              'Photography, styling and art direction: Bernadetta Nycz',
              'Model: Tumi Kekane',
            ]}
            buttonText="Client Website"
            buttonDestination="https://youknowmewell.net/"
          />
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>

              <Col xs="12" className="mb-5">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col>
              <Col
                lg="6"
                className="mb-5 mb-lg-5
               pr-lg-4"
              >
                <img src={imgS7} alt="" className="img-fluid w-100" />
              </Col>

              <Col
                lg="6"
                className="mb-5 mb-lg-5
               pr-lg-4"
              >
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>

              <Col
                lg="6"
                className="mb-5 mb-lg-5
               pr-lg-4"
              >
                <img src={imgS8} alt="" className="img-fluid w-100" />
              </Col>

              <Col
                lg="6"
                className="mb-5 mb-lg-5
               pr-lg-4"
              >
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>

              <Col
                lg="6"
                className="mb-5 mb-lg-5
               pr-lg-4"
              >
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col>

              <Col
                lg="6"
                className="mb-5 mb-lg-5
               pr-lg-4"
              >
                <img src={imgS6} alt="" className="img-fluid w-100" />
              </Col>

              {/* <Col lg="6" className="pl-lg-4">
                <img src={imgS7} alt="" className="img-fluid w-100" />
              </Col> */}
            </Row>
          </Container>
        </Section>

        {/* <Section mt="22%" bg="dark">
          <Container>
            {/* <Row

              css={`
                margin-top: -40%;
              `}
            >
              { <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>}
            </Row> }

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
